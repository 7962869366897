import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
// import moment from "moment"

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import axiosPost from "../../helpers/axiosPost";
import axiosPut from "../../helpers/axiosPut";
import {
  setClaimCargoId,
  setDataFormOne,
  setDataInvoice,
} from "../../store/klaim/actions";

import Button from "../../components/Button/Button";
import CertificateDetail from "../../components/Detail/CertificateDetail";
import Form from "../../components/Form/Form";
import ScrollToHere from "../../components/ScrollToHere";
import Spinner from "../../components/Spinner/Spinner";
import Subheading from "../../components/Subheading/Subheading";
import axiosGet from "../../helpers/axiosGet";

const formId = "claim-form-one";

export default function FormOne() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataFormOne, claimCargoId } = useSelector(
    (state) => state.klaimStore
  );
  const { detailFromCargo } = useSelector((state) => state.detailStore);

  const header = detailFromCargo.certificateOwner || "";

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    axiosGet({
      url: `/claim-cargo/invoice/${detailFromCargo?.policyNumber}-${detailFromCargo?.certificateNumber}`,
      callback: (res) => {
        setInvoice(res.data);
        dispatch(setDataInvoice(res.data));
      },
      errorCallback: (res) => {
        console.log(res);
      },
    });
  }, [
    detailFromCargo?.certificateNumber,
    detailFromCargo?.policyNumber,
    dispatch,
  ]);

  const handleNext = (data) => {
    ScrollToHere();
    setLoading(true);
    setError(null);

    dispatch(setDataFormOne(data));

    const body = {
      productType: detailFromCargo.productCode,
      companyName: detailFromCargo.companyName,
      policyNumber: detailFromCargo.policyNumber,
      conveyance: detailFromCargo.mainVessel,
      certificateNo: detailFromCargo.certificateNumber,

      typeOfLoss: data.typeOfLoss || null,
      accidentType: data.accidentType || null,
      accidentDate: data.accidentDate,
      accidentDetail: data.accidentDetail,
      accidentLocation: data.accidentLocation,

      picName: data.picName,
      picPhoneNumber: data.picPhoneNumber,
      picEmail: data.picEmail,
      containerNo: data.containerNo,
      claimAmount: data.claimAmount,
    };

    // jika claimCargoId udah ada, hit api update biar gak bikin claim baru
    if (claimCargoId) {
      axiosPut({
        url: `/claim-cargo/update/${claimCargoId}`,
        data: body,
        callback: () => {
          setLoading(false);

          const encode = window.btoa(claimCargoId);
          if (invoice.status === 0) {
            navigate(`/klaim/new/${encode}/summary`);
          } else {
            navigate(`/klaim/new/${encode}/next`);
          }
        },
        errorCallback: (err) => {
          setLoading(false);
          setModal(true);
          setError(err);
        },
      });
    } else {
      // jika belum, bikin claim baru
      axiosPost({
        url: "/claim-cargo/save",
        data: body,
        callback: (res) => {
          setLoading(false);
          dispatch(setClaimCargoId(res.data.claimCargoId));

          const encode = window.btoa(res.data.claimCargoId);
          if (invoice.status === 0) {
            navigate(`/klaim/new/${encode}/summary`);
          } else {
            navigate(`/klaim/new/${encode}/next`);
          }
        },
        errorCallback: (err) => {
          setLoading(false);
          setModal(true);
          setError(err);
        },
      });
    }
  };

  // const generateString = (length) => {
  //   const chars = "abcdefghijklmnopqrstuvwxyz"

  //   let result = ""
  //   for (let i = 0; i < length; i++) {
  //     result += chars.charAt(Math.floor(Math.random() * chars.length))
  //   }

  //   return result
  // }

  // const generateCapsString = (length) => {
  //   const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

  //   let result = ""
  //   for (let i = 0; i < length; i++) {
  //     result += chars.charAt(Math.floor(Math.random() * chars.length))
  //   }

  //   return result
  // }

  // const generateNumber = (length) => {
  //   const numbers = "0123456789"

  //   let result = ""
  //   for (let i = 0; i < length; i++) {
  //     result += numbers.charAt(Math.floor(Math.random() * numbers.length))
  //   }

  //   return result
  // }

  // const fillForm = () => {
  //   dispatch(
  //     setDataFormOne({
  //       accidentDate: moment().format("YYYY-MM-DD"),
  //       accidentDetail: generateString(250),
  //       accidentLocation: generateCapsString(20),
  //       containerNo: `${generateCapsString(2)}${generateNumber(8)}`,
  //       claimAmount: generateNumber(8),
  //       picEmail: `${generateString(5)}@gmail.com`,
  //       picName: generateCapsString(5),
  //       picPhoneNumber: `08${generateNumber(10)}`,
  //     })
  //   )

  //   window.location.reload()
  // }

  return (
    <section className="overflow-hidden">
      {loading ? (
        <Spinner height={70} />
      ) : (
        <>
          <MDBRow className="g-4">
            <MDBCol lg="8">
              <Subheading>Detail Klaim</Subheading>

              <Form
                header={header}
                id={formId}
                handleNext={handleNext}
                defVals={dataFormOne}
                detailFromCargo={detailFromCargo}
              />

              {/* <button onClick={fillForm}>FILL FORM</button> */}
            </MDBCol>

            <MDBCol lg="4">
              <Subheading>Detail Certificate</Subheading>

              <CertificateDetail
                detail={detailFromCargo}
                isFromCargo
                invoice={invoice}
              />
            </MDBCol>
          </MDBRow>

          <div className="d-flex justify-content-end mt-5">
            <Button endIcon="arrow-right" form={formId} type="submit">
              Selanjutnya
            </Button>
          </div>
        </>
      )}

      <MDBModal show={modal} setShow={setModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent className="bg-danger">
            <MDBModalHeader>
              <MDBModalTitle className="text-light fw-bold">
                Error
              </MDBModalTitle>
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                onClick={toggleModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-light">
              {error && error.message}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </section>
  );
}
