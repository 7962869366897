import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CertificateDetail from "../../components/Detail/CertificateDetail";
import ClaimDetail from "../../components/Detail/ClaimDetail";
import { resetKlaim, setDataInvoice } from "../../store/klaim/actions";
import Button from "../../components/Button/Button";
import Subheading from "../../components/Subheading/Subheading";
import { useEffect, useState } from "react";
import axiosGet from "../../helpers/axiosGet";
import Spinner from "../../components/Spinner/Spinner";
import styled from "styled-components";

const StyledStatusBar = styled.div`
  margin-bottom: 15px;
  width: 100%;
  font-family: "Avenir", sans-serif;
  font-weight: 700;
  z-index: 10;

  .code {
    background: red;
    color: white;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    text-align: center;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 1rem;
    }
  }
`;

export default function Summary() {
  const { name, id } = useParams();
  const { detailFromCargo } = useSelector((state) => state.detailStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState({});
  const [claim, setClaim] = useState({});
  const [invoice, setInvoice] = useState(null);

  const decode = window.atob(id);

  useEffect(() => {
    axiosGet({
      url: `/claim-cargo/id/${decode}`,
      callback: (res) => {
        setLoading(false);
        setDetail(res.data);
        setClaim(res.data.claim);
        axiosGet({
          url: `/claim-cargo/invoice/${detailFromCargo?.policyNumber}-${detailFromCargo?.certificateNumber}`,
          callback: (res) => {
            setInvoice(res.data);
            dispatch(setDataInvoice(res.data));
          },
          errorCallback: (res) => {
            console.log(res);
          },
        });
      },
      errorCallback: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  }, [
    decode,
    detailFromCargo?.certificateNumber,
    detailFromCargo?.policyNumber,
    dispatch,
  ]);

  const handleSubmit = () => {
    dispatch(resetKlaim(true));
    navigate(`/riwayat/${id}`);
  };

  return (
    <section className="overflow-hidden">
      {loading ? (
        <Spinner height={70} />
      ) : (
        <>
          {invoice?.status === 0 && (
            <StyledStatusBar>
              <div className="code">
                Untuk melanjutkan klaim harap lakukan pembayaran premi terlebih
                dahulu.
              </div>
            </StyledStatusBar>
          )}
          <MDBRow className="g-4">
            <MDBCol md="8">
              <ClaimDetail detail={detail} claim={claim} docs={[]} />
            </MDBCol>

            <MDBCol md="4">
              <Subheading>Detail Certificate</Subheading>

              <CertificateDetail detail={detail} claim={claim} />
            </MDBCol>
          </MDBRow>
          <div className="d-flex justify-content-between mt-5">
            <Button
              secondary
              startIcon="arrow-left"
              onClick={() =>
                navigate(
                  invoice?.status === 0 ? `/klaim` : `/klaim/${name}/${id}/next`
                )
              }
            >
              Kembali
            </Button>

            {invoice?.status !== 0 && (
              <Button endIcon="arrow-right" onClick={handleSubmit}>
                Selanjutnya
              </Button>
            )}
          </div>
        </>
      )}
    </section>
  );
}
