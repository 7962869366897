const initialState = {
  dataFormOne: {
    accidentDate: "",
    accidentDetail: "",
    accidentLocation: "",
    containerNo: "",
    claimAmount: "",
    picEmail: "",
    picName: "",
    picPhoneNumber: "",
    accidentType: "",
  },
  claimCargoId: null,
  docsOtherIndexes: [],
  dataInvoice: {},
};

const klaimReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA_FORM_ONE":
      return {
        ...state,
        dataFormOne: action.payload,
      };
    case "SET_CLAIM_CARGO_ID":
      return {
        ...state,
        claimCargoId: action.payload,
      };
    case "SET_DOCS_OTHER_INDEXES":
      return {
        ...state,
        docsOtherIndexes: action.payload,
      };
    case "SET_DATA_INVOICE":
      return {
        ...state,
        dataInvoice: action.payload,
      };
    case "RESET_KLAIM":
      return (state = initialState);

    default:
      return state;
  }
};

export default klaimReducer;
