import moment from "moment";

import styled from "styled-components";
import { saveAs } from "file-saver";
import { useForm } from "react-hook-form";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import { useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";

import Button from "../Button/Button";
import StatusList from "../StatusList";
import Modal from "../Modal/Modal";
import axiosPut from "../../helpers/axiosPut";
import axiosGet from "../../helpers/axiosGet";

const StyledStatusBar = styled.div`
  margin-top: 63px;
  position: fixed;
  width: 100%;
  z-index: 1;
  font-family: "Avenir", sans-serif;
  font-weight: 700;
  z-index: 10;

  .code {
    background: ${(props) => props.color};
    color: white;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    text-align: center;

    div span:nth-child(odd) {
      background: #fff323;
      color: black;
      padding: 0 0.25rem;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 1rem;
    }
  }

  .desc {
    background: #ffffff;
    padding: 0.25rem;
    text-align: center;
  }
`;

const StatusBar = ({ id, detail, status, claim, doc, isExpired, invoice }) => {
  const { statusCode, description } = status;
  const { expiredDate } = claim;

  let bar = {};
  StatusList.forEach((x) => {
    if (x.code === statusCode) {
      bar.color = x.color;
      bar.text = x.text;
      bar.date =
        statusCode === "100"
          ? claim.createdDate
          : ["110", "111", "140", "201"].includes(statusCode)
          ? detail.nextProgressDeadlineDate
          : claim.updatedDate || claim.createdDate;

      bar.button = isExpired
        ? false
        : ["100", "110", "111", "140", "201"].includes(statusCode);

      bar.buttonText =
        statusCode === "140" ? "Lengkapi Dokumen LOD" : "Lengkapi Dokumen";

      bar.description = isExpired
        ? `Maaf klaim telah kadaluarsa. Maksimal pengajuan klaim anda ${moment(
            expiredDate
          ).format("D-MMM-YYYY")}`
        : description === "null"
        ? null
        : description === "undefined"
        ? null
        : description;
    }
  });

  // get date, month, and year from date
  const d = moment(bar.date).date();
  const m = moment(bar.date).month();
  const y = moment(bar.date).year();

  const month = moment(m + 1, "MM").format("MMMM");

  // const months = [
  //   "Januari",
  //   "Februari",
  //   "Maret",
  //   "April",
  //   "Mei",
  //   "Juni",
  //   "Juli",
  //   "Agustus",
  //   "September",
  //   "Oktober",
  //   "November",
  //   "Desember",
  // ]

  // const month = months[m]

  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const handleButton = (id) => {
    if (statusCode === "140") {
      toggleModal();
    } else {
      navigate(`/klaim/update/${id}/next`);
    }
  };

  return (
    <Fragment>
      {invoice?.status === 0 ? (
        <StyledStatusBar color={"red"}>
          <div className="code">
            Untuk melanjutkan klaim harap lakukan pembayaran premi terlebih
            dahulu.
          </div>
        </StyledStatusBar>
      ) : (
        <StyledStatusBar color={bar.color}>
          <div className="code">
            <div>{bar.text}</div>

            <div>
              <span>{d}</span>
              <span> - </span>
              <span>{month}</span>
              <span> - </span>
              <span>{y}</span>
            </div>

            {bar.button && (
              <Button
                transparent
                endIcon="angle-double-right"
                onClick={() => handleButton(id)}
              >
                {bar.buttonText}
              </Button>
            )}
          </div>

          {bar.description && (
            <div className="desc shadow">{bar.description}</div>
          )}
        </StyledStatusBar>
      )}

      <Modal
        show={modal}
        setShow={setModal}
        toggleModal={toggleModal}
        title="Lengkapi Dokumen LOD"
      >
        <ModalForm
          noClaim={detail.noClaim}
          toggleModal={toggleModal}
          doc={doc}
        />
      </Modal>
    </Fragment>
  );
};

const ModalForm = ({ noClaim, toggleModal, doc }) => {
  const { register, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleForm = (data) => {
    setLoading(true);

    const fd = new FormData();

    fd.append("noClaim", noClaim);
    if (data.description) {
      fd.append("description", data.description || "");
    }
    fd.append("file", data.file[0] || null);

    axiosPut({
      url: `/claim-cargo/submit-lod-sign`,
      data: fd,
      callback: () => {
        setLoading(false);
        toggleModal();
        window.location.reload();
      },
      errorCallback: (err) => {
        console.log(err);
        setLoading(false);
      },
    });
  };

  const handleDownloadLod = (doc) => {
    const { fileId, filename } = doc;
    setDownloadLoading(true);

    axiosGet({
      url: `/claim-cargo/download/file-id/${fileId}`,
      responseType: "blob",
      callback: () => {
        setDownloadLoading(false);
      },
      errorCallback: (res) => {
        setDownloadLoading(false);

        // save file to computer
        saveAs(res, filename);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleForm)}>
      <MDBRow className="g-3">
        <MDBCol size="12">
          <Button
            secondary
            small
            onClick={() => handleDownloadLod(doc)}
            loading={downloadLoading}
            disabled={downloadLoading}
            fullWidth
          >
            Download Dokumen LOD
          </Button>
        </MDBCol>

        <MDBCol size="12">
          <label htmlFor="description">Description</label>

          <input
            type="text"
            className="form-control form-control-lg"
            id="description"
            {...register("description")}
            placeholder="Description"
          />
        </MDBCol>

        <MDBCol size="12">
          <label htmlFor="file">Dokumen</label>

          <input
            type="file"
            className="form-control form-control-lg"
            id="file"
            {...register("file")}
          />

          <p className="mb-0 mt-1 small" style={{ color: "#006881" }}>
            Note: Harap download Dokumen LOD di atas dan upload kembali setelah
            ditandatangani
          </p>
        </MDBCol>

        <div className="d-flex justify-content-end mt-4">
          <Button type="submit" loading={loading} disabled={loading}>
            Submit
          </Button>
        </div>
      </MDBRow>
    </form>
  );
};

export default StatusBar;
